import Alpine from 'alpinejs';
Alpine.start();
screenDetectTasks();

window.addEventListener('hashchange', function () {
    history.replaceState({}, document.title, ".");
});

function screenDetectTasks() {
    // viewport and full window dimensions will change
    let viewport_width = window.innerWidth;
    let bodyElement = document.querySelector('body');
    // console.log(viewport_width);
    if (viewport_width <= 428) {
        //Is Mobile
        bodyElement.classList.add('mobile');

    }

    if (viewport_width > 429 && viewport_width <= 991) {
        //Is Tablet
        bodyElement.classList.add('tablet');
    }

    if (viewport_width >= 992) {
        //Is Desktop
        bodyElement.classList.add('desktop');
    }
}

